document.querySelectorAll('.sub-menu li').forEach(function (menuItem) {
  menuItem.addEventListener('mousemove', function (e) {
    const li = this;
    const rect = li.getBoundingClientRect();
    const mouseX = e.clientX - rect.left; // Mouse X position within li
    const mouseY = e.clientY - rect.top;  // Mouse Y position within li

    // Calculate the angle of the gradient based on mouse position
    const angle = Math.atan2(mouseY - rect.height / 2, mouseX - rect.width / 2) * (180 / Math.PI) + 180;

    // Apply the gradient with the dynamic angle on the ::before pseudo-element
    li.style.setProperty('--gradient-angle', `${angle}deg`);
  });
});

jQuery(document).ready(function ($) {
  $(".site-header__navigation .menu > li").each(function () {
    var $topLevelItem = $(this);
    var $directSubItems = $topLevelItem.children(".sub-menu");

    if ($directSubItems.length === 1) {
      var $subMenuItems = $directSubItems.children("li");

      // Check if there are two or more direct sub-menu items that have children
      var countWithChildren = $subMenuItems.filter(function () {
        return $(this).children(".sub-menu").length > 0;
      }).length;

      if (countWithChildren >= 2) {
        $topLevelItem.addClass("split-menu");
      }
    }
  });
});

// add a custom hook to include a logo in the mobile menu, and add some structure to it

jQuery(document).ready(function ($) {
  var $menuNav = $('.pp-menu-nav.pp-off-canvas-menu');

  if ($menuNav.find('.offcanvas-logo').length === 0) {
    $menuNav.prepend('<div class="offcanvas-logo"><a href="/"><img src="/wp-content/uploads/2025/02/causaLens.svg" alt="CausaLens"></a></div>');
  }

  if ($menuNav.find('.offcanvas-header').length === 0) {
    var $logo = $menuNav.find('.offcanvas-logo');
    var $closeBtn = $menuNav.find('.pp-menu-close-btn');

    if ($logo.length && $closeBtn.length) {
      $logo.add($closeBtn).wrapAll('<div class="offcanvas-header"></div>');
    }
  }
});

(function ($) {
  $(document).ready(function () {
    var header = $('.header-scroll, .full-nav');
    var triggerElement = $('h1');

    if (triggerElement.length) {
      var triggerPosition = triggerElement.offset().top;

      $(window).on('scroll', function () {
        if ($(window).scrollTop() >= triggerPosition) {
          header.addClass('scrolled');
        } else {
          header.removeClass('scrolled');
        }
      });
    }
  });
})(jQuery);

// Sliders

if (typeof jQuery === "undefined" || typeof jQuery.fn.slick === "undefined") {
  console.error("jQuery or Slick not loaded");
} else {
  jQuery(document).ready(function ($) {
    const $slider = $('.testimonials__slider-init');
    if ($slider.length === 0) {
      console.warn("Slider not found, waiting for Beaver Builder...");
      return;
    }

    const $counter = $('.testimonials__slider--controlls--number-counter');
    const $prevArrow = $('.testimonials__slider--controlls--left');
    const $nextArrow = $('.testimonials__slider--controlls--right');

    $slider.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      dots: false,
      arrows: false,
      infinite: false,
      speed: 600,
    });

    $prevArrow.on('click', function () {
      $slider.slick('slickPrev');
    });

    $nextArrow.on('click', function () {
      $slider.slick('slickNext');
    });

    $slider.on('init reInit afterChange', function (event, slick, currentSlide) {
      let currentIndex = (currentSlide ? currentSlide : 0) + 1;
      let totalSlides = slick.slideCount;
      $counter.text(`${currentIndex} / ${totalSlides}`);

      $prevArrow.toggleClass('inactive', currentSlide === 0);
      $nextArrow.toggleClass('inactive', currentSlide === totalSlides - 1);
    });

    setTimeout(() => $slider.slick('refresh'), 500);
  });
}

jQuery(document).ready(function ($) {
  $('.fl-accordion-item').each(function () {
    var $item = $(this);
    var $content = $item.find('.fl-accordion-content');
    var $button = $item.find('.fl-accordion-button');

    // Find the first image inside the accordion content
    var $image = $content.find('img').first();

    if ($image.length) {
      var $clonedImage = $image.clone();
      $clonedImage.addClass('accordion-button-image');
      $button.prepend($clonedImage);

      // Remove the original image from the content area
      $image.remove();
    }
  });
});

jQuery.noConflict();

(function ($) {
  jQuery(document).ready(function ($) {
    var $carousel = $('.custom-carousel__carousel--init');

    $carousel.slick({
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      autoplay: false,
      centerMode: false,
      centerPadding: '20px',
      focusOnSelect: true,
      prevArrow: $('.custom-carousel__intro--controlls--left'),
      nextArrow: $('.custom-carousel__intro--controlls--right'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '20px',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    });

    // Disable arrows when at the first or last slide
    $carousel.on('afterChange', function (event, slick, currentSlide) {
      var $prevArrow = $('.custom-carousel__intro--controlls--left');
      var $nextArrow = $('.custom-carousel__intro--controlls--right');

      // Check if the carousel is at the first slide
      if (currentSlide === 0) {
        $prevArrow.addClass('disabled');
      } else {
        $prevArrow.removeClass('disabled');
      }

      // Check if the carousel is at the last slide
      if (currentSlide === slick.slideCount - 1) {
        $nextArrow.addClass('disabled');
      } else {
        $nextArrow.removeClass('disabled');
      }
    });

    // Initial check on page load to set the correct state
    $carousel.trigger('afterChange', [null, null, $carousel.slick('slickCurrentSlide')]);
  });
})(jQuery);





